const urlConfigs = {
  testHostUrl: 'https://dev.kaizencopilot.com/',
  prodHostUrl: 'https://backend.kaizencopilot.com/',
  localHostUrl: 'http://127.0.0.1:8000/',
  authServerUrl: 'https://backend.retrocausal.tech',
  prodReportsUrl: 'https://reports.kaizencopilot.com/',
  testReportsUrl: 'https://dev-reports.kaizencopilot.com/',
  // testReportsUrl: 'https://dev-reports.kaizencopilot.com:8001/',
  // prodReportsUrl: 'https://backend.kaizencopilot.com/',
  // testReportsUrl: 'https://dev.kaizencopilot.com/'
}

export const baseUrlOptions = {
  [urlConfigs.prodHostUrl]: 'PROD',
  [urlConfigs.testHostUrl]: 'DEV',
  [urlConfigs.localHostUrl]: 'LOCAL'
}

export default urlConfigs